import React, { useCallback, useState, useEffect } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	fieldValidation,
} from "../../../constants/mainKeys";
import {
	addButtonSpinner,
	addPageSpinner,
	removeButtonSpinner,
	removePageSpinner,
} from "../../../store/spinner/actions";
import AlertService from "../../../services/alertService";
import { useDispatch } from "react-redux";
import {
	Card,
	CardBody,
	Col,
	Row,
	CardTitle,
	CardSubtitle,
	Label,
	Input,
	Form,
	Button,
} from "reactstrap";
import { Table } from "react-bootstrap";
import uuid from "react-uuid";
import ActionButton from "../../../components/Buttons/ActionButton";
import NoData from "../../../components/NoData/NoData";
import Accordion from "react-bootstrap/Accordion";
import { useSelector } from "react-redux";
import FormLanguage from "../../../components/FormLanguage/FormLanguage";
import ApiService from "../../../services/apiService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import MainService from "../../../services/mainService";
import ShowMoreText from "react-show-more-text";
import RichTextEditor from "../../../components/RichTextEditor/RichTextEditor";

const buttonSpinnerId = uuid();

const CourseForm = props => {
	const dispatch = useDispatch();
	const { language, formLanguage } = useSelector(state => state.Languages);
	const { courseId } = useParams();
	const { history } = props;
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [isInvalidMonths, setIsInvalidMonths] = useState(false);
	const [isInvalidWeek, setIsInvalidWeek] = useState(false);
	const [isInvalidSubject, setIsInvalidSubject] = useState(false);
	const [defaultLecturers, setDefaultLecturers] = useState([]);
	const [lecturers, setLecturers] = useState([]);
	const [defaultGuests, setDefaultGuests] = useState([]);
	const [guests, setGuests] = useState([]);
	const [courseFaculties, setCourseFaculties] = useState([]);
	const [courseTypes, setCourseTypes] = useState([]);
	const [changes, setChanges] = useState(false);

	const [values, setValues] = useState({
		title: "",
		registationLink: "",
		duration: {
			key: "Duration",
			value: "",
		},
		level: {
			key: "Level",
			value: "",
		},
		price: {
			key: "Price per month",
			value: {
				regularPrice: "",
				intensivePrice: "",
			},
		},
		showRegistrationForm: false,
		isPaid: false,
		regular: {
			key: "Regular",
			value: "",
		},
		intensive: {
			key: "Intensive",
			value: "",
		},
		infoSection1: {
			key: "Info section 1",
			value: null,
		},
		infoSection2: {
			key: "Info section 2",
			value: null,
		},
		infoSection3: {
			key: "Info section 3",
			value: null,
		},
		description: {
			key: "Description",
			value: null,
		},
		courseType: null,
		facultyType: null,
		syllabus: {
			key: "Syllabus",
			value: [
				{
					monthName: "",
					weeks: [
						{
							weekName: "",
							subjects: [""],
						},
					],
				},
			],
		},
		lecturerIds: [],
		guestIds: [],
		showOnHomePage: false,
		isActive: true,
	});

	useEffect(() => {
		if (courseId) {
			getCourseById();
		} else {
			setValues(values => ({
				...values,
				description: {
					key: "Description",
					value: "",
				},
				infoSection1: {
					key: "Info section 1",
					value: "",
				},
				infoSection2: {
					key: "Info section 2",
					value: "",
				},
				infoSection3: {
					key: "Info section 3",
					value: "",
				},
			}));
		}
		getLecturers();
		// getGuests();
		getCourseTypes();
		getCourseFaculties();
		return () => {
			setLecturers([]);
			// setGuests([]);
			setCourseFaculties([]);
			setCourseTypes([]);
		};
	}, [formLanguage, courseId]);

	const getCourseById = () => {
		if (!formLanguage) {
			return false;
		}
		const spinnerId = uuid();
		setPageSpinner(spinnerId);
		ApiService.getCourseById(+courseId, formLanguage.id)
			.then(response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (!data.isPaid) {
						data.price = {
							key: "Price per month",
							value: {
								regularPrice: "",
								intensivePrice: "",
							},
						};
					}
					if (!data.registationLink) {
						data.registationLink = "";
					}
					setValues(data);
				}
				extractPageSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const searchLecturer = event => {
		if (!event.target.value.trim()) {
			setLecturers(defaultLecturers);
		}
		setLecturers(
			defaultLecturers.filter(lecturer =>
				lecturer.fullName.includes(event.target.value),
			),
		);
	};

	const searchGuest = event => {
		if (!event.target.value.trim()) {
			setGuests(defaultGuests);
		}
		setGuests(
			defaultGuests.filter(guest =>
				guest.fullName.includes(event.target.value),
			),
		);
	};

	const getLecturers = () => {
		if (!formLanguage) {
			return false;
		}
		const spinnerId = uuid();
		setPageSpinner(spinnerId);
		ApiService.getAllLecturers(formLanguage.id)
			.then(response => {
				if (response && response.data) {
					setLecturers(response.data);
					setDefaultLecturers(response.data);
					setGuests(response.data);
					setDefaultGuests(response.data);
				}
				extractPageSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	// const getGuests = () => {
	// 	if (!formLanguage) {
	// 		return false;
	// 	}
	// 	const spinnerId = uuid();
	// 	setPageSpinner(spinnerId);
	// 	ApiService.getAllGuests(formLanguage.id)
	// 		.then(response => {
	// 			if (response && response.data) {
	// 				setGuests(response.data);
	// 				setDefaultGuests(response.data);
	// 			}
	// 			extractPageSpinner(spinnerId);
	// 		})
	// 		.catch(error => getFail(error, spinnerId));
	// };

	const getCourseTypes = () => {
		if (!formLanguage) {
			return false;
		}
		const spinnerId = uuid();
		setPageSpinner(spinnerId);
		ApiService.getCourseTypes(formLanguage.id)
			.then(response => {
				if (response && response.data) {
					const data = [...response.data];
					setCourseTypes(data);
				}
				extractPageSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getCourseFaculties = () => {
		if (!formLanguage) {
			return false;
		}
		const spinnerId = uuid();
		setPageSpinner(spinnerId);
		ApiService.getFaculties(formLanguage.id)
			.then(response => {
				if (response && response.data) {
					const data = [...response.data];
					setCourseFaculties(data);
				}
				extractPageSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const attachOrDetachLecturer = lecturerId => {
		setValues(prevValues => {
			const isAttached = prevValues.lecturerIds.includes(lecturerId);
			if (isAttached) {
				return {
					...prevValues,
					lecturerIds: prevValues.lecturerIds.filter(id => id !== lecturerId),
				};
			} else {
				return {
					...prevValues,
					lecturerIds: [...prevValues.lecturerIds, lecturerId],
				};
			}
		});
	};

	const attachOrDetachGuest = guestId => {
		setValues(prevValues => {
			const isAttached = prevValues.guestIds.includes(guestId);
			if (isAttached) {
				return {
					...prevValues,
					guestIds: prevValues.guestIds.filter(id => id !== guestId),
				};
			} else {
				return {
					...prevValues,
					guestIds: [...prevValues.guestIds, guestId],
				};
			}
		});
	};

	const onChange = (event, field, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		setValues(values => ({
			...values,
			[field]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};

	const onChangeKeyValue = (event, fieldPath, maxLength = Infinity) => {
		const newValue = event.target.value;
		if (maxLength && newValue.length >= maxLength) return;
		const newValues = { ...values };
		let targetObject = newValues;
		const fieldPathArray = fieldPath.split(".");
		for (let i = 0; i < fieldPathArray.length - 1; i++) {
			targetObject = targetObject[fieldPathArray[i]];
		}
		targetObject[fieldPathArray[fieldPathArray.length - 1]] = newValue;
		setIsInvalidSubmit(false);
		setValues(newValues);
	};

	const onEditorChange = (event, fieldPath, maxLength = Infinity) => {
		const newValue = event.editor.getData();
		if (maxLength && newValue.length >= maxLength) return;
		const newValues = { ...values };
		let targetObject = newValues;
		const fieldPathArray = fieldPath.split(".");
		for (let i = 0; i < fieldPathArray.length - 1; i++) {
			targetObject = targetObject[fieldPathArray[i]];
		}
		targetObject[fieldPathArray[fieldPathArray.length - 1]] = newValue;
		setIsInvalidSubmit(false);
		setValues(newValues);
		setChanges(true);
	};

	const onPriceChange = (event, fieldName, maxLength = Infinity) => {
		const newValue = event.target.value;
		if (maxLength && newValue.length >= maxLength) return;
		const newValues = { ...values };
		newValues.price.value[fieldName] = newValue;
		setIsInvalidSubmit(false);
		setValues(newValues);
	};

	const addMonth = () => {
		let emptyField = values.syllabus.value.find(
			item => !item.monthName.trim().length,
		);
		if (emptyField) {
			setIsInvalidMonths(true);
			return false;
		}
		let newSyllabus = { ...values.syllabus };
		const newMonth = {
			monthName: "",
			weeks: [
				{
					weekName: "",
					subjects: [""],
				},
			],
		};
		newSyllabus.value.push(newMonth);
		setValues(prevState => ({
			...prevState,
			syllabus: newSyllabus,
		}));
	};

	const onMonthNameChange = (event, monthIndex, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		const newValues = { ...values };
		newValues.syllabus.value[monthIndex].monthName = event.target.value;
		setValues(newValues);
		setIsInvalidMonths(false);
	};

	const removeMonth = index => {
		let newSyllabus = { ...values.syllabus };
		newSyllabus.value = newSyllabus.value.filter((_, i) => i !== index);
		setValues(prevState => ({
			...prevState,
			syllabus: newSyllabus,
		}));
		setIsInvalidMonths(false);
		setChanges(true);
	};

	const addWeek = monthIndex => {
		const newValues = { ...values };
		newValues.syllabus.value[monthIndex].weeks.push({
			weekName: "",
			subjects: [""],
		});
		setValues(newValues);
	};

	const onWeekChange = (event, monthIndex, weekIndex, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		const newValues = { ...values };
		newValues.syllabus.value[monthIndex].weeks[weekIndex].weekName =
			event.target.value;
		setValues(newValues);
	};

	const removeWeek = (monthIndex, weekIndex) => {
		const newValues = { ...values };
		newValues.syllabus.value[monthIndex].weeks.splice(weekIndex, 1);
		setValues(newValues);
		setChanges(true);
	};

	const addSubject = (monthIndex, weekIndex) => {
		const newValues = { ...values };
		newValues.syllabus.value[monthIndex].weeks[weekIndex].subjects.push("");
		setValues(newValues);
	};

	const onSubjectChange = (
		event,
		monthIndex,
		weekIndex,
		subjectIndex,
		maxLength = Infinity,
	) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		const newValues = { ...values };
		newValues.syllabus.value[monthIndex].weeks[weekIndex].subjects[
			subjectIndex
		] = event.target.value;
		setValues(newValues);
	};

	const removeSubject = (monthIndex, weekIndex, subjectIndex) => {
		const newValues = { ...values };
		newValues.syllabus.value[monthIndex].weeks[weekIndex].subjects.splice(
			subjectIndex,
			1,
		);
		setValues(newValues);
		setChanges(true);
	};

	const onRadioChange = (event, name, typeId) => {
		setValues(prevState => ({
			...prevState,
			[name]: typeId,
		}));
	};

	const onCheckBoxChange = (event, name) => {
		if (name === "isPaid" && !event.target.checked) {
			const newValues = { ...values };
			newValues.price = {
				key: "Price per month",
				value: {
					regularPrice: "",
					intensivePrice: "",
				},
			};
			setValues(prevState => ({
				...prevState,
				[name]: event.target.checked,
			}));
		}
		setValues(prevState => ({
			...prevState,
			[name]: event.target.checked,
		}));
	};

	const onSubmit = event => {
		event && event.preventDefault();
		if (!formLanguage) {
			return false;
		}
		if (
			!values.courseType ||
			!values.facultyType ||
			!values.title.trim().length ||
			(values.isPaid &&
				!values.price.value.regularPrice.trim().length &&
				!values.price.value.intensivePrice.trim().length)
		) {
			setIsInvalidSubmit(true);
			MainService.scrollToInvalidFieldPosition();
			return false;
		}
		if (courseId) values.id = +courseId;
		values.languageId = formLanguage.id;
		setButtonSpinner(buttonSpinnerId);
		(courseId
			? ApiService.updateCourse(values)
			: ApiService.createCourse(values)
		)
			.then(response => {
				AlertService.alert(SUCCESS_KEY, "Data saved");
				extractButtonSpinner(buttonSpinnerId);
				history.push(`/${language}/courses`);
			})
			.catch(error => getFail(error, buttonSpinnerId));
	};

	const setButtonSpinner = useCallback(spinner => {
		dispatch(addButtonSpinner(spinner));
	}, []);

	const setPageSpinner = useCallback(spinner => {
		dispatch(addPageSpinner(spinner));
	}, []);

	const extractButtonSpinner = useCallback(spinner => {
		dispatch(removeButtonSpinner(spinner));
	}, []);

	const extractPageSpinner = useCallback(spinner => {
		dispatch(removePageSpinner(spinner));
	}, []);

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && extractPageSpinner(spinnerId);
		spinnerId && extractButtonSpinner(spinnerId);
	};

	return (
		<div className="page-content position-relative">
			<div className="container-fluid">
				<Breadcrumbs title="Ardy" breadcrumbItem="Course Form" />
				<FormLanguage />
				<hr className="mt-0" />
				<Card>
					<CardBody>
						<CardTitle>How to create/update a course ?</CardTitle>
						<CardSubtitle className="mb-4">
							<ShowMoreText
								lines={2}
								more="Read more"
								less="Less"
								className="mb-3 mt-0 more-less-text"
								anchorClass="underline cursor-pointer pt-0"
								expanded={false}
								truncatedEndingComponent={
									<span>
										...
										<br />
									</span>
								}>
								<div className="form-info-text">
									<p className="mb-1">
										{" "}
										<span className="fw-500">Title:</span> Enter the title of
										the course.
									</p>
									<p className="mb-1">
										{" "}
										<span className="fw-500">Is Paid: </span>Check this option
										if the course is paid, and additional pricing details will
										appear.
									</p>
									<p className="mb-1">
										{" "}
										<span className="fw-500">Pricing: </span> Specify the price
										per month for the course, including regular and intensive
										prices.
									</p>
									<p className="mb-1">
										{" "}
										<span className="fw-500">Duration: </span>Enter the course
										duration and its level (e.g., "6 months" and "Beginner").
									</p>
									<p className="mb-1">
										{" "}
										<span className="fw-500">Regular and Intensive: </span>
										Define regular and intensive schedules for the course.
									</p>
									<p className="mb-1">
										{" "}
										<span className="fw-500">Description: </span>Provide a
										detailed description of the course content.
									</p>
									<p className="mb-1">
										{" "}
										<span className="fw-500">Show on Home Page: </span>Choose
										whether to display this course on the home page.
									</p>
									<p className="mb-1">
										{" "}
										<span className="fw-500">Course Type: </span> Select the
										type of the course from the available options.
									</p>
									<p className="mb-1">
										{" "}
										<span className="fw-500">Course Faculty: </span> Choose the
										faculty or department responsible for the course.
									</p>
									<p className="mb-1">
										{" "}
										<span className="fw-500">Lecturers: </span>You can attach or
										detach lecturers to the course using checkboxes. Search for
										lecturers and easily manage their association with the
										course.
									</p>
									<p className="mb-1">
										{" "}
										<span className="fw-500">Guests: </span>You can attach or
										detach guests to the course using checkboxes. Search for
										guests and easily manage their association with the
										course.
									</p>
									{/* <p className='mb-1'> <span className='fw-500'>Benefits: </span> Specify the benefits of the course, including a short description and detailed explanations. You can add multiple benefits and remove them as needed.</p> */}
									<p className="mb-1">
										<span className="fw-500">Syllabus: </span>Organize the
										course syllabus by months, weeks, and subjects. Add and
										remove months, weeks, and subjects as required.
									</p>
									{/* <p className='mb-1'><span className='fw-500'>FAQ (Frequently Asked Questions): </span>Provide answers to frequently asked questions about the course. You can add, edit, and remove questions and answers as needed.</p> */}
									<p className="mb-1">
										<span className="fw-500">Save Changes: </span>Once you've
										entered all the course details, use the "Save" button to
										save your changes. The button will be enabled when there are
										unsaved changes. This page offers comprehensive tools for
										managing course information, ensuring that all details are
										well-documented and easily accessible.
									</p>
								</div>
							</ShowMoreText>
						</CardSubtitle>
						<Form onChange={() => setChanges(true)} onSubmit={onSubmit}>
							<Row>
								<Col md={12}>
									<div className="">
										<Row>
											<Col md={6}>
												<Label>Title*</Label>
												<Input
													type="text"
													placeholder="Ex. Graphic Design"
													className={`form-control  ${isInvalidSubmit && !values.title.trim().length
														? "error-border"
														: ""
														}`}
													value={values.title || ""}
													onChange={event =>
														onChange(event, "title", fieldValidation.length_255)
													}
												/>
											</Col>
											<Col md={6}>
												<Label>Registation Link</Label>
												<Input
													type="text"
													placeholder="Ex. https://docs.google.com/document..."
													className={`form-control`}
													value={values.registationLink || ""}
													onChange={event =>
														onChange(
															event,
															"registationLink",
															fieldValidation.length_255,
														)
													}
												/>
											</Col>
										</Row>
									</div>
								</Col>
								<div
									className={`${values.isPaid ? "border rounded my-3 " : ""}`}>
									<Col md={6} className="my-3">
										<CardTitle>Is Paid</CardTitle>
										<div className="form-check form-check-inline font-size-16">
											<Input
												id={`isPaid`}
												type="checkbox"
												name="courseType"
												className="form-check-input"
												value={values.isPaid}
												checked={values.isPaid}
												onChange={event => onCheckBoxChange(event, "isPaid")}
											/>
											<Label
												htmlFor={`isPaid`}
												className="form-check-label font-size-13">
												Yes
											</Label>
										</div>
									</Col>
									{values.isPaid ? (
										<Col md={12}>
											<Row className="mb-3">
												<Col md={6}>
													<Label>Price per month</Label>
													<Input
														type="text"
														className={`form-control`}
														placeholder="Ex. Price per month"
														value={values.price.key || ""}
														onChange={event =>
															onChangeKeyValue(
																event,
																"price.key",
																fieldValidation.length_100,
															)
														}
													/>
												</Col>
											</Row>
											<Row className="mb-3">
												<Col md={6}>
													<Label>Regular price</Label>
													<Input
														type="text"
														className={`form-control mt-1 ${isInvalidSubmit &&
															values.isPaid &&
															!values.price.value.regularPrice.trim().length
															? "error-border"
															: ""
															}`}
														placeholder="Ex. 10(USD)"
														value={values.price.value.regularPrice || ""}
														onChange={event =>
															onPriceChange(
																event,
																"regularPrice",
																fieldValidation.length_100,
															)
														}
													/>
												</Col>
												<Col md={6}>
													<Label>Intensive price</Label>
													<Input
														type="text"
														className={`form-control mt-1 ${isInvalidSubmit &&
															values.isPaid &&
															!values.price.value.intensivePrice.trim().length
															? "error-border"
															: ""
															}`}
														placeholder="Ex. 25(USD)"
														value={values.price.value.intensivePrice || ""}
														onChange={event =>
															onPriceChange(
																event,
																"intensivePrice",
																fieldValidation.length_100,
															)
														}
													/>
												</Col>
											</Row>
										</Col>
									) : null}
								</div>
								<Col md={6}>
									<Row className="border rounded pt-2 pb-3 me-md-1 mb-3">
										<Col sm={12}>
											<Label>Duration</Label>
										</Col>
										<Col lg={6}>
											<Input
												type="text"
												className={`form-control mt-1`}
												placeholder="Ex. Duration (key)"
												value={values.duration.key || ""}
												onChange={event =>
													onChangeKeyValue(
														event,
														"duration.key",
														fieldValidation.length_100,
													)
												}
											/>
										</Col>
										<Col lg={6}>
											<Input
												type="text"
												className={`form-control mt-1`}
												placeholder="Ex. 6 months (value)"
												value={values.duration.value || ""}
												onChange={event =>
													onChangeKeyValue(
														event,
														"duration.value",
														fieldValidation.length_100,
													)
												}
											/>
										</Col>
									</Row>
								</Col>
								<Col md={6}>
									<Row className="border rounded pt-2 pb-3 ms-md-1 mb-3">
										<Col sm={12}>
											<Label>Level</Label>
										</Col>
										<Col lg={6}>
											<Input
												type="text"
												className={`form-control mt-1`}
												placeholder="Ex. Level (key)"
												value={values.level.key || ""}
												onChange={event =>
													onChangeKeyValue(
														event,
														"level.key",
														fieldValidation.length_100,
													)
												}
											/>
										</Col>
										<Col lg={6}>
											<Input
												type="text"
												className={`form-control mt-1`}
												placeholder="Ex. Beginner (value)"
												value={values.level.value || ""}
												onChange={event =>
													onChangeKeyValue(
														event,
														"level.value",
														fieldValidation.length_100,
													)
												}
											/>
										</Col>
									</Row>
								</Col>
								<Col md={6}>
									<Row className="border rounded pt-2 pb-3 me-md-1 mb-3">
										<Col sm={12}>
											<Label>Regular</Label>
										</Col>
										<Col lg={6}>
											<Input
												type="text"
												className={`form-control mt-1`}
												placeholder="Ex. Regular (key)"
												value={values.regular.key || ""}
												onChange={event =>
													onChangeKeyValue(
														event,
														"regular.key",
														fieldValidation.length_100,
													)
												}
											/>
										</Col>
										<Col lg={6}>
											<Input
												type="text"
												className={`form-control mt-1`}
												placeholder="Ex. 12 hrs / week (value)"
												value={values.regular.value || ""}
												onChange={event =>
													onChangeKeyValue(
														event,
														"regular.value",
														fieldValidation.length_100,
													)
												}
											/>
										</Col>
									</Row>
								</Col>
								<Col md={6}>
									<Row className="border rounded pt-2 pb-3 ms-md-1 mb-3">
										<Col sm={12}>
											<Label>Intensive</Label>
										</Col>
										<Col lg={6}>
											<Input
												type="text"
												className={`form-control mt-1`}
												placeholder="Ex. Intensive (key)"
												value={values.intensive.key || ""}
												onChange={event =>
													onChangeKeyValue(
														event,
														"intensive.key",
														fieldValidation.length_100,
													)
												}
											/>
										</Col>
										<Col lg={6}>
											<Input
												type="text"
												className={`form-control mt-1`}
												placeholder="Ex. 22 hrs / week (value)"
												value={values.intensive.value || ""}
												onChange={event =>
													onChangeKeyValue(
														event,
														"intensive.value",
														fieldValidation.length_100,
													)
												}
											/>
										</Col>
									</Row>
								</Col>
								<Col md={12}>
									<Row className="border rounded pt-2 pb-3 mb-3">
										<Col sm={12}>
											<Label>Description</Label>
										</Col>
										<Col lg={12}>
											<Input
												type="text"
												className={`form-control mt-1`}
												placeholder="Ex. Course Description (key)"
												value={values.description.key || ""}
												onChange={event =>
													onChangeKeyValue(
														event,
														"description.key",
														fieldValidation.length_100,
													)
												}
											/>
										</Col>
										<Col lg={12}>
											{typeof values.description.value === "string" ? (
												<RichTextEditor
													value={values.description.value}
													blockClassName="custom-rich-text-editor small-rich-text-editor mt-1 rounded overflow-hidden"
													lableClassName="fw-500 font-size-14"
													onEditorChange={event =>
														onEditorChange(event, "description.value")
													}
												/>
											) : null}
											{/* <textarea
                        name="description"
                        rows="4"
                        placeholder='Ex. Lorem Ipsum is simply dummy text of the printing and typesetting industry. (value)'
                        className="form-control resize-none mt-1"
                        value={values.description.value}
                        onChange={(event) => onChangeKeyValue(event, "description.value", fieldValidation.length_2000)}
                      />
                      <small><i>Max {fieldValidation.length_2000} characters.</i></small> */}
										</Col>
									</Row>
								</Col>
								<Col md={12}>
									<Row className="border rounded pt-2 pb-3 mb-3">
										<Col sm={12}>
											<Label>Info section 1</Label>
										</Col>
										<Col lg={12}>
											<Input
												type="text"
												className={`form-control mt-1`}
												placeholder="Ex. Is This course is for You ? (key)"
												value={values.infoSection1.key || ""}
												onChange={event =>
													onChangeKeyValue(
														event,
														"infoSection1.key",
														fieldValidation.length_100,
													)
												}
											/>
										</Col>
										<Col lg={12}>
											{/* <textarea
                        rows="3"
                        placeholder='Ex. Lorem Ipsum is simply dummy text of the printing and typesetting industry. (value)'
                        className="form-control resize-none mt-1"
                        value={values.infoSection1.value}
                        onChange={(event) => onChangeKeyValue(event, "infoSection1.value", fieldValidation.length_2000)}
                      />
                      <small><i>Max {fieldValidation.length_2000} characters.</i></small> */}
											{typeof values.infoSection1.value === "string" ? (
												<RichTextEditor
													value={values.infoSection1.value}
													blockClassName="custom-rich-text-editor small-rich-text-editor mt-1 rounded overflow-hidden"
													lableClassName="fw-500 font-size-14"
													onEditorChange={event =>
														onEditorChange(event, "infoSection1.value")
													}
												/>
											) : null}
										</Col>
									</Row>
								</Col>
								<Col md={12}>
									<Row className="border rounded pt-2 pb-3 mb-3">
										<Col sm={12}>
											<Label>Info section 2</Label>
										</Col>
										<Col lg={12}>
											<Input
												type="text"
												className={`form-control mt-1`}
												placeholder="Ex. Competence framework (key)"
												value={values.infoSection2.key || ""}
												onChange={event =>
													onChangeKeyValue(
														event,
														"infoSection2.key",
														fieldValidation.length_100,
													)
												}
											/>
										</Col>
										<Col lg={12}>
											{/* <textarea
                        rows="3"
                        placeholder='Ex. Lorem Ipsum is simply dummy text of the printing and typesetting industry. (value)'
                        className="form-control resize-none mt-1"
                        value={values.infoSection2.value}
                        onChange={(event) => onChangeKeyValue(event, "infoSection2.value", fieldValidation.length_2000)}
                      />
                      <small><i>Max {fieldValidation.length_2000} characters.</i></small> */}
											{typeof values.infoSection2.value === "string" ? (
												<RichTextEditor
													value={values.infoSection2.value}
													blockClassName="custom-rich-text-editor small-rich-text-editor mt-1 rounded overflow-hidden"
													lableClassName="fw-500 font-size-14"
													onEditorChange={event =>
														onEditorChange(event, "infoSection2.value")
													}
												/>
											) : null}
										</Col>
									</Row>
								</Col>
								<Col md={12}>
									<Row className="border rounded pt-2 pb-3 mb-3">
										<Col sm={12}>
											<Label>Info section 3</Label>
										</Col>
										<Col lg={12}>
											<Input
												type="text"
												className={`form-control`}
												placeholder="Ex. What you will learn ? (key)"
												value={values.infoSection3.key || ""}
												onChange={event =>
													onChangeKeyValue(
														event,
														"infoSection3.key",
														fieldValidation.length_100,
													)
												}
											/>
										</Col>
										<Col lg={12}>
											{/* <textarea
												rows="3"
												placeholder="Ex. Lorem Ipsum is simply dummy text of the printing and typesetting industry. (value)"
												className="form-control resize-none mt-1"
												value={values.infoSection3.value}
												onChange={event =>
													onChangeKeyValue(event, "infoSection3.value")
												}
											/> */}
											{
												typeof values.infoSection3.value === "string" ?
													<RichTextEditor
														value={values.infoSection3.value}
														blockClassName="custom-rich-text-editor small-rich-text-editor mt-1 rounded overflow-hidden"
														lableClassName="fw-500 font-size-14"
														onEditorChange={event => onEditorChange(event, "infoSection3.value")}
													/>
													: null
											}
										</Col>
									</Row>
								</Col>
								<Col md={12}>
									<hr />
								</Col>
								<Col lg={6}>
									<CardTitle className="mb-3">Is Active</CardTitle>
									<div className="form-check form-check-inline font-size-16">
										<Input
											id={`isActive`}
											type="checkbox"
											name="isActive"
											className="form-check-input"
											value={values.isActive}
											checked={values.isActive}
											onChange={event =>
												onCheckBoxChange(event, "isActive")
											}
										/>
										<Label
											htmlFor={`isActive`}
											className="form-check-label font-size-13">
											Yes
										</Label>
									</div>
								</Col>
								<Col lg={6}>
									<CardTitle className="mb-3">Show on home page</CardTitle>
									<div className="form-check form-check-inline font-size-16">
										<Input
											id={`showOnHomePage`}
											type="checkbox"
											name="showOnHomePage"
											className="form-check-input"
											value={values.showOnHomePage}
											checked={values.showOnHomePage}
											onChange={event =>
												onCheckBoxChange(event, "showOnHomePage")
											}
										/>
										<Label
											htmlFor={`showOnHomePage`}
											className="form-check-label font-size-13">
											Yes
										</Label>
									</div>
								</Col>
								<Col md={12}>
									<hr />
								</Col>
								{courseTypes && courseTypes.length ? (
									<Auxiliary>
										<Col lg={12}>
											<CardTitle className="mb-3">Course type*</CardTitle>
											{courseTypes.map((item, index) => {
												return (
													<div
														key={index}
														className="form-check form-check-inline font-size-16">
														<Input
															id={`courseType_${index}`}
															type="radio"
															name="courseType"
															className={`form-check-input ${isInvalidSubmit && !values.courseType
																? "error-border"
																: ""
																}`}
															value={item.id}
															checked={values.courseType === item.id}
															onChange={event =>
																onRadioChange(event, "courseType", item.id)
															}
														/>
														<Label
															htmlFor={`courseType_${index}`}
															className="form-check-label font-size-13">
															{item.title}
														</Label>
													</div>
												);
											})}
										</Col>
										<Col md={12}>
											<hr />
										</Col>
									</Auxiliary>
								) : null}
								{courseFaculties && courseFaculties.length ? (
									<Auxiliary>
										<Col lg={12}>
											<CardTitle className="mb-3">Course faculty*</CardTitle>
											{courseFaculties.map((item, index) => {
												return (
													<div
														key={index}
														className="form-check form-check-inline font-size-16 me-4">
														<Input
															id={`facultyType_${index}`}
															type="radio"
															name="facultyType"
															className={`form-check-input ${isInvalidSubmit && !values.facultyType
																? "error-border"
																: ""
																}`}
															value={item.id}
															checked={values.facultyType === item.id}
															onChange={event =>
																onRadioChange(event, "facultyType", item.id)
															}
														/>
														<Label
															htmlFor={`facultyType_${index}`}
															className="form-check-label font-size-13">
															{item.title}
														</Label>
													</div>
												);
											})}
										</Col>
										<Col md={12}>
											<hr />
										</Col>
									</Auxiliary>
								) : null}
								<Col lg={12}>
									<CardTitle className="mb-3">Lecturers</CardTitle>
									<Accordion defaultActiveKey="lecturers">
										<Accordion.Item eventKey="lecturers">
											<Accordion.Header>
												Attach or detach a lecturer
											</Accordion.Header>
											<Accordion.Body className="">
												{defaultLecturers && defaultLecturers.length ? (
													<div className="mb-3 position-relative">
														<input
															className="form-control"
															style={{ paddingLeft: "40px" }}
															type="search"
															placeholder="Search"
															onChange={event => searchLecturer(event)}
														/>
														<i
															style={{ left: "10px" }}
															className="bx bx-search-alt-2 font-size-24 absolute-center-horizontal opacity-50"
														/>
													</div>
												) : null}
												{lecturers && lecturers.length ? (
													<Table
														responsive
														className="table mb-0 table-bordered">
														<thead>
															<tr>
																<th className="no-wrap">Image</th>
																<th className="no-wrap">Full Name</th>
																<th className="no-wrap">Position</th>
																<th className="no-wrap">Attach / Detach</th>
															</tr>
														</thead>
														<tbody>
															{lecturers.map((item, index) => (
																<tr key={index}>
																	{item.imagePath ? (
																		<td>
																			<img
																				className="rounded-circle avatar-sm"
																				src={item.imagePath}
																				alt="/"
																			/>
																		</td>
																	) : (
																		<td className="position-relative">
																			<i className="bx bx-user font-size-24"></i>
																		</td>
																	)}
																	<td>
																		<p
																			className="word-break-break-word max-line-2"
																			title={item.fullName}>
																			{item.fullName}
																		</p>
																	</td>
																	<td>
																		<p
																			className="word-break-break-word max-line-2"
																			title={item.position}>
																			{item.position}
																		</p>
																	</td>
																	<td>
																		<div className="form-check mb-2 d-flex h-100 align-items-center ms-2">
																			<Input
																				id={`lecturer_${item.id}`}
																				type="checkbox"
																				className="custom-control-input font-size-18 me-2 mt-0"
																				checked={
																					values.lecturerIds &&
																						values.lecturerIds.length &&
																						values.lecturerIds.find(
																							lecturerId =>
																								item.id === lecturerId,
																						)
																						? true
																						: false
																				}
																				onChange={() => {
																					attachOrDetachLecturer(item.id);
																				}}
																			/>
																			<Label
																				className="form-check-label"
																				htmlFor={`lecturer_${item.id}`}>
																				{!item.attachToCourse
																					? "Attach"
																					: "Unattach"}
																			</Label>
																		</div>
																	</td>
																</tr>
															))}
														</tbody>
													</Table>
												) : (
													<NoData width={200} height={200} />
												)}
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</Col>
								<Col lg={12} className="mt-3">
									<CardTitle className="mb-3">Guests</CardTitle>
									<Accordion defaultActiveKey="guests">
										<Accordion.Item eventKey="guests">
											<Accordion.Header>
												Attach or detach a guest
											</Accordion.Header>
											<Accordion.Body className="">
												{defaultGuests && defaultGuests.length ? (
													<div className="mb-3 position-relative">
														<input
															className="form-control"
															style={{ paddingLeft: "40px" }}
															type="search"
															placeholder="Search"
															onChange={event => searchGuest(event)}
														/>
														<i
															style={{ left: "10px" }}
															className="bx bx-search-alt-2 font-size-24 absolute-center-horizontal opacity-50"
														/>
													</div>
												) : null}
												{guests && guests.length ? (
													<Table
														responsive
														className="table mb-0 table-bordered">
														<thead>
															<tr>
																<th className="no-wrap">Image</th>
																<th className="no-wrap">Full Name</th>
																<th className="no-wrap">Position</th>
																<th className="no-wrap">Attach / Detach</th>
															</tr>
														</thead>
														<tbody>
															{guests.map((item, index) => (
																<tr key={index}>
																	{item.imagePath ? (
																		<td>
																			<img
																				className="rounded-circle avatar-sm"
																				src={item.imagePath}
																				alt="/"
																			/>
																		</td>
																	) : (
																		<td className="position-relative">
																			<i className="bx bx-user font-size-24"></i>
																		</td>
																	)}
																	<td>
																		<p
																			className="word-break-break-word max-line-2"
																			title={item.fullName}>
																			{item.fullName}
																		</p>
																	</td>
																	<td>
																		<p
																			className="word-break-break-word max-line-2"
																			title={item.position}>
																			{item.position}
																		</p>
																	</td>
																	<td>
																		<div className="form-check mb-2 d-flex h-100 align-items-center ms-2">
																			<Input
																				id={`guest_${item.id}`}
																				type="checkbox"
																				className="custom-control-input font-size-18 me-2 mt-0"
																				checked={
																					values.guestIds &&
																						values.guestIds.length &&
																						values.guestIds.find(
																							guestId =>
																								item.id === guestId,
																						)
																						? true
																						: false
																				}
																				onChange={() => {
																					attachOrDetachGuest(item.id);
																				}}
																			/>
																			<Label
																				className="form-check-label"
																				htmlFor={`guest_${item.id}`}>
																				{!item.attachToCourse
																					? "Attach"
																					: "Unattach"}
																			</Label>
																		</div>
																	</td>
																</tr>
															))}
														</tbody>
													</Table>
												) : (
													<NoData width={200} height={200} />
												)}
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</Col>
								<Col md={12}>
									<hr />
								</Col>
								<Col md={12}>
									<Row className="border rounded pt-2 pb-3 mb-3">
										<Col sm={12}>
											<Label>Syllabus</Label>
										</Col>
										<Col lg={12}>
											<Input
												type="text"
												className={`form-control mt-1`}
												placeholder="Ex. Syllabus (key)"
												value={values.syllabus.key || ""}
												onChange={event =>
													onChangeKeyValue(event, "syllabus.key")
												}
											/>
										</Col>
										<Col lg={12}>
											<Row>
												<Accordion defaultActiveKey={["0"]} alwaysOpen>
													{values.syllabus.value.map(
														(syllabusItem, monthIndex) => {
															return (
																<div
																	key={`month_${monthIndex}`}
																	className="d-flex align-items-center">
																	<Accordion.Item
																		className={`mt-1 w-100 ${isInvalidMonths &&
																			!syllabusItem.monthName.trim().length
																			? "error-border"
																			: ""
																			}`}
																		eventKey={`month_${monthIndex}`}>
																		<Accordion.Header>
																			<span className="fw-500">
																				Month {monthIndex + 1}
																			</span>
																		</Accordion.Header>
																		<Accordion.Body>
																			<input
																				type="text"
																				className={`
                                form-control
                                resize-none
                                ${monthIndex ? "mt-1" : ""}
                                ${isInvalidMonths &&
																						!syllabusItem.monthName.trim().length
																						? "error-border"
																						: ""
																					}
                              `}
																				placeholder="Month name"
																				value={syllabusItem.monthName || ""}
																				onChange={event =>
																					onMonthNameChange(event, monthIndex)
																				}
																			/>
																			{syllabusItem.weeks.map(
																				(syllabusWeek, weekIndex) => {
																					return (
																						<div
																							key={`month_${monthIndex}_week_${weekIndex}`}
																							className="d-flex align-items-center">
																							<Accordion.Item
																								className="mt-1 w-100"
																								eventKey={`month_${monthIndex}_week_${weekIndex}`}>
																								<Accordion.Header>
																									<span className="fw-500">
																										Week {weekIndex + 1}
																									</span>
																								</Accordion.Header>
																								<Accordion.Body>
																									<input
																										type="text"
																										className={`form-control mt-1 ${isInvalidWeek &&
																											!syllabusWeek.weekName.trim()
																												.length
																											? "error-border"
																											: ""
																											}`}
																										placeholder="Week name"
																										value={
																											syllabusWeek.weekName ||
																											""
																										}
																										onChange={event =>
																											onWeekChange(
																												event,
																												monthIndex,
																												weekIndex,
																											)
																										}
																									/>

																									{syllabusWeek.subjects.map(
																										(
																											syllabusWeekSubject,
																											subjectIndex,
																										) => (
																											<div
																												key={`month_${monthIndex}_week_${weekIndex}_subject_${subjectIndex}`}
																												className="position-relative">
																												<input
																													type="text"
																													className={`form-control mt-1 pr-5 ${isInvalidSubject &&
																														!syllabusWeekSubject.trim()
																															.length
																														? "error-border"
																														: ""
																														}`}
																													placeholder="Subject"
																													value={
																														syllabusWeekSubject ||
																														""
																													}
																													onChange={event =>
																														onSubjectChange(
																															event,
																															monthIndex,
																															weekIndex,
																															subjectIndex,
																														)
																													}
																												/>
																												{syllabusWeek.subjects &&
																													syllabusWeek.subjects
																														.length > 1 ? (
																													<Button
																														style={{
																															right: "5px",
																														}}
																														type="button"
																														color="danger"
																														outline
																														className="btn-sm p-0 d-flex justify-content-center align-items-center absolute-center-horizontal"
																														onClick={() => {
																															removeSubject(
																																monthIndex,
																																weekIndex,
																																subjectIndex,
																															);
																														}}>
																														<i className="bx bx-x font-size-22"></i>
																													</Button>
																												) : null}
																											</div>
																										),
																									)}

																									<Button
																										color="primary"
																										type="button"
																										outline
																										className="btn-sm d-flex justify-content-center align-items-center ms-1 mt-1 ms-auto"
																										onClick={() =>
																											addSubject(
																												monthIndex,
																												weekIndex,
																											)
																										}>
																										<span className="font-size-18 me-1">
																											+
																										</span>
																										<span className="font-size-12 me-1">
																											Add subject
																										</span>
																									</Button>
																								</Accordion.Body>
																							</Accordion.Item>
																							{syllabusItem.weeks &&
																								syllabusItem.weeks.length > 1 ? (
																								<Button
																									color="danger"
																									type="button"
																									outline
																									className="btn-sm ms-1 p-1 d-flex justify-content-center align-items-center"
																									onClick={() => {
																										removeWeek(
																											monthIndex,
																											weekIndex,
																										);
																									}}>
																									<i className="bx bx-trash font-size-20"></i>
																								</Button>
																							) : null}
																						</div>
																					);
																				},
																			)}
																			<Button
																				color="primary"
																				type="button"
																				outline
																				className="btn-sm d-flex justify-content-center align-items-center ms-1 mt-1 ms-auto"
																				onClick={() => addWeek(monthIndex)}>
																				<span className="font-size-18 me-1">
																					+
																				</span>
																				<span className="font-size-12 me-1">
																					Add week
																				</span>
																			</Button>
																		</Accordion.Body>
																	</Accordion.Item>
																	{values.syllabus.value &&
																		values.syllabus.value.length > 1 ? (
																		<Button
																			color="danger"
																			outline
																			type="button"
																			className="btn-sm p-2 ms-1 d-flex justify-content-center align-items-center"
																			onClick={() => {
																				removeMonth(monthIndex);
																			}}>
																			<i className="bx bx-trash font-size-22"></i>
																		</Button>
																	) : null}
																</div>
															);
														},
													)}
												</Accordion>
												<div className="d-flex justify-content-end">
													<Button
														type="button"
														color="primary"
														outline
														className="btn-sm d-flex justify-content-center align-items-center mt-3"
														onClick={addMonth}>
														<span className="font-size-18 me-1">+</span>
														<span className="font-size-12 me-1">Add month</span>
													</Button>
												</div>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
							<div className="d-flex justify-content-end mt-2">
								<ActionButton
									type="submit"
									name="Save"
									disabled={!changes}
									spinnerClass={`w_50`}
									className="btn btn-primary btn-block px-4"
									spinnerId={buttonSpinnerId}
								/>
							</div>
						</Form>
					</CardBody>
				</Card>
			</div>
		</div>
	);
};

export default CourseForm;
