class TranslationService {
  translations = null;

  constructor(translations) {
    this.translations = [...translations];
  }

  translate = (key) => {
    if (key) {
      const selectedTranslation = this.translations.find(data => data.key === key);
      if (selectedTranslation) return selectedTranslation.value;
      else return "";
    }
  }
}

export default TranslationService;